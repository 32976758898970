/* eslint-disable import/no-cycle */
import React, { useState } from "react"
import PropTypes from "prop-types"
import shortid from "shortid"
import { Link } from "gatsby"
import clsx from "clsx"

import { makeStyles } from "@material-ui/core/styles"
import {
  AppBar,
  Container,
  Drawer,
  Grid,
  Hidden,
  IconButton,
  Link as MaterialLink,
  List,
  ListItem,
  ListItemText,
  Menu,
  MenuItem,
  Slide,
  Typography,
  useScrollTrigger,
} from "@material-ui/core"
import PhoneIcon from "@material-ui/icons/Phone"
import ScheduleIcon from "@material-ui/icons/Schedule"
import FacebookIcon from "@material-ui/icons/Facebook"
import InstagramIcon from "@material-ui/icons/Instagram"
import MenuIcon from "@material-ui/icons/Menu"
import CloseIcon from "@material-ui/icons/Close"
import TranslateIcon from "@material-ui/icons/Translate"

import { LocaleContext } from "../contexts/LocaleContext"

import Logo from "../img/logo.webp"

const useStyles = makeStyles(theme => ({
  header: {
    background: theme.palette.common.white,
    position: "sticky",
    top: 0,
    left: 0,
    width: "100%",
    boxShadow: "0 -13px 17px black",
    zIndex: 99,

    [theme.breakpoints.down("sm")]: {
      height: 60,
    },
    [theme.breakpoints.up("md")]: {
      height: 110,
    },
  },
  topHeader: {
    borderBottom: "1px solid rgba(0,0,0,.1)",
  },
  phone: {
    color: theme.palette.turistBlue.main,
    textDecoration: "none",
    fontWeight: 600,
    fontSize: 12,
    display: "inline-flex",
    alignItems: "center",
  },

  schedule: {
    marginLeft: 15,
    fontSize: 12,
    display: "inline-flex",
    alignItems: "center",
    color: "#888",
  },

  languageMenu: {
    display: "flex",

    "& li": {
      listStyleType: "none",
      borderRight: "1px solid rgba(0,0,0,0.1)",
    },

    "& li:last-child": {
      borderRight: "none",
    },
  },

  languageItem: {
    color: "#888",
    textDecoration: "none",
    padding: "0 5px",

    "&:hover": {
      color: "#333",
    },

    "&.active": {
      fontWeight: 600,
    },
  },

  bottomHeader: {
    marginTop: 5,
  },

  logo: {
    padding: "5px 0",

    "& img": {
      width: 200,
    },
  },

  mainMenu: {
    display: "flex",

    "& li": {
      listStyleType: "none",
    },
  },

  menuItem: {
    color: "#333",
    textDecoration: "none",
    padding: "0 5px",

    "&:hover": {
      color: theme.palette.primary.main,
      textDecoration: "none",
    },

    "&.active": {
      fontWeight: 600,
    },
  },

  mobileLogo: {
    padding: "5px 0",

    "& img": {
      width: 170,
    },
  },

  drawerPaper: {
    width: "100%",
  },

  drawerItem: {
    color: "#333",
    borderBottom: "1px solid rgba(0,0,0,.1)",
  },
}))

function HideOnScroll(props) {
  const { children, window } = props
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({
    target: window ? window() : undefined,
  })

  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>
  )
}

HideOnScroll.propTypes = {
  children: PropTypes.element.isRequired,
}

const Header = props => {
  const { menu, alternateLanguages } = props
  const lang = React.useContext(LocaleContext)
  const i18n = lang.i18n[lang.locale]

  const classes = useStyles()

  const [drawerState, setDrawerState] = useState(false)
  const [menuState, setMenuState] = useState(null)

  const getAlternateLanguageLink = key => {
    if (alternateLanguages) {
      const alternateLanguage = alternateLanguages.find(al => al.lang === key)

      if (alternateLanguage) {
        return alternateLanguage.path
      }
    }

    return `/${lang.i18n[key].path}/`
  }

  const toggleMenu = event => {
    setMenuState(event.currentTarget)
  }

  const handleCloseMenu = () => {
    setMenuState(null)
  }

  const toggleDrawer = open => event => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return
    }

    setDrawerState(open)
  }

  return (
    <>
      <Hidden smDown implementation="css">
        <HideOnScroll {...props}>
          <header className={classes.header}>
            <div className={classes.topHeader}>
              <Container>
                <Grid
                  container
                  direction="row"
                  justify="space-between"
                  alignItems="center"
                >
                  <Grid item>
                    <MaterialLink
                      href="tel://+40749680770"
                      className={classes.phone}
                    >
                      <PhoneIcon style={{ fontSize: 14 }} />
                      &nbsp;+40 749 680 770
                    </MaterialLink>

                    <Typography component="span" className={classes.schedule}>
                      <ScheduleIcon style={{ fontSize: 14 }} />
                      &nbsp;{i18n.schedule}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <ul className={classes.languageMenu}>
                      {Object.keys(lang.i18n).map(key => (
                        <li key={shortid.generate()}>
                          <Link
                            hrefLang={lang.i18n[key].path}
                            to={getAlternateLanguageLink(key)}
                            className={clsx({
                              [classes.languageItem]: true,
                              active: lang.locale === lang.i18n[key].locale,
                            })}
                          >
                            {lang.i18n[key].langName}
                          </Link>
                        </li>
                      ))}
                      <li>
                        <MaterialLink
                          href={i18n.fbUrl}
                          className={classes.languageItem}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <FacebookIcon style={{ fontSize: 17 }} />
                        </MaterialLink>
                      </li>
                      <li>
                        <MaterialLink
                          href={i18n.instaUrl}
                          className={classes.languageItem}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <InstagramIcon style={{ fontSize: 17 }} />
                        </MaterialLink>
                      </li>
                    </ul>
                  </Grid>
                </Grid>
              </Container>
            </div>

            <div className={classes.bottomHeader}>
              <Container>
                <Grid container direction="row" alignItems="center" spacing={4}>
                  <Grid item className={classes.logo}>
                    <Link to={`/${i18n.path}/`}>
                      <img
                        src={Logo}
                        alt="Turist in Transilvania"
                        width="200"
                        height="53"
                      />
                    </Link>
                  </Grid>
                  <Grid item>
                    <nav>
                      <ul className={classes.mainMenu}>
                        <li>
                          <Link
                            to={`/${i18n.path}/`}
                            className={classes.menuItem}
                          >
                            {i18n.home}
                          </Link>
                        </li>
                        {menu.map(item => (
                          <li key={shortid.generate()}>
                            {item.internal_link && (
                              <Link
                                to={item.internal_link}
                                className={classes.menuItem}
                              >
                                {item.label}
                              </Link>
                            )}

                            {item.url && item.url.target && (
                              <MaterialLink
                                href={item.url.url}
                                target={item.url.target}
                                className={classes.menuItem}
                              >
                                {item.label}
                              </MaterialLink>
                            )}
                          </li>
                        ))}
                      </ul>
                    </nav>
                  </Grid>
                </Grid>
              </Container>
            </div>
          </header>
        </HideOnScroll>
      </Hidden>

      <Hidden mdUp implementation="css">
        <HideOnScroll {...props}>
          <AppBar color="default">
            <div className={classes.mobileHeader}>
              <Container>
                <Grid
                  container
                  direction="row"
                  justify="space-between"
                  alignItems="center"
                >
                  <Grid item className={classes.mobileLogo}>
                    <Link to={`/${i18n.path}/`}>
                      <img
                        src={Logo}
                        alt="Turist in Transilvania"
                        width="170"
                        height="45"
                      />
                    </Link>
                  </Grid>
                  <Grid>
                    <IconButton color="primary" onClick={toggleMenu}>
                      <TranslateIcon />
                    </IconButton>
                    <Menu
                      anchorEl={menuState}
                      keepMounted
                      open={Boolean(menuState)}
                      onClose={handleCloseMenu}
                    >
                      {Object.keys(lang.i18n).map(key => (
                        <MenuItem
                          component={Link}
                          hrefLang={lang.i18n[key].path}
                          to={getAlternateLanguageLink(key)}
                          key={shortid.generate()}
                          onClick={handleCloseMenu}
                        >
                          {lang.i18n[key].langName}
                        </MenuItem>
                      ))}
                    </Menu>
                    <IconButton color="primary" onClick={toggleDrawer(true)}>
                      <MenuIcon />
                    </IconButton>
                  </Grid>
                </Grid>
              </Container>
            </div>
          </AppBar>
        </HideOnScroll>
      </Hidden>
      <Drawer
        open={drawerState}
        onClose={toggleDrawer(false)}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <div
          role="presentation"
          onClick={toggleDrawer(false)}
          onKeyDown={toggleDrawer(false)}
        >
          <Container>
            <Grid
              container
              direction="row"
              justify="space-between"
              alignItems="center"
            >
              <Grid item className={classes.mobileLogo}>
                <Link to={`/${i18n.path}/`}>
                  <img src={Logo} alt="Turist in Transilvania" />
                </Link>
              </Grid>
              <Grid>
                <IconButton color="primary">
                  <CloseIcon />
                </IconButton>
              </Grid>
            </Grid>
          </Container>
          <List component="nav" aria-label="main mailbox folders">
            <ListItem
              button
              component={Link}
              to={`/${i18n.path}/`}
              className={classes.drawerItem}
            >
              <ListItemText>{i18n.home}</ListItemText>
            </ListItem>
            {menu.map(item => (
              <div key={shortid.generate()}>
                {item.internal_link && (
                  <ListItem
                    button
                    component={Link}
                    to={item.internal_link}
                    className={classes.drawerItem}
                  >
                    <ListItemText>{item.label}</ListItemText>
                  </ListItem>
                )}
                {item.url && item.url.target && (
                  <ListItem
                    button
                    component={MaterialLink}
                    href={item.url.url}
                    target={item.url.target}
                    className={classes.drawerItem}
                  >
                    <ListItemText>{item.label}</ListItemText>
                  </ListItem>
                )}
              </div>
            ))}
          </List>
        </div>
      </Drawer>
    </>
  )
}

export default Header

Header.propTypes = {
  menu: PropTypes.array.isRequired,
  alternateLanguages: PropTypes.oneOfType([PropTypes.array, PropTypes.node])
    .isRequired,
}
