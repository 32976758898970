module.exports = {
  ro: {
    path: "ro",
    locale: "ro",
    siteLanguage: "ro",
    ogLang: "ro_RO",
    hrefLang: "ro-RO",
    ogUrl: "https://www.turistintransilvania.com/ro",
    title:
      "Circuite turistice, cazare și experiențe în Transilvania - Turist în Transilvania",
    description:
      "Descoperă circuite turistice, spații de cazare originale și experiențe unice în Transilvania",
    headline: "Turist in Transilvania",
    schedule: "Luni - Duminică: 10:00 - 18:00",
    phone: "+40 749 680 770",
    mail: "contact@turistintransilvania.com",
    langName: "Română",
    german: "Deutsch",
    germanShort: "DE",
    romanian: "Română",
    romanianShort: "RO",
    english: "English",
    englishShort: "EN",
    fbUrl: "https://facebook.com/turistintransilvania",
    instaUrl: "https://www.instagram.com/touristintransylvania",
    helpNeeded: "Ai nevoie de ajutor?",
    aboutUs: "Despre noi",
    menu: "Meniu",
    discover: "Descoperă",
    language: "Limbă",
    tours: "Circuite",
    toursSlogan: "Circuite de o zi în inima Transilvaniei",
    inTransylvania: "în Transilvania",
    viewAllTours: "Vezi toate circuitele",
    seeMore: "Vezi mai multe",
    accommodations: "Cazare",
    accommodationsSlogan:
      "Spații de cazare în cetăți medievale și clădiri construite în urmă cu sute de ani",
    priceFrom: "Preț de la",
    viewAllAccommodations: "Vezi toate cazările",
    why: "De ce",
    turistInTransilvania: "Turist în Transilvania",
    reasonsSlogan:
      "Trei motive pentru care să alegi circuitele și cazările noastre",
    stepTitle1: "Alege circuitul sau spațiul de cazare preferat",
    stepTitle1Accommodation:
      "Selectează data de check-in și check-out și numărul de persoane.",
    stepContent1:
      "Alege unul din cele peste 10 circuite istorice, culinare, de drumetie sau de welness sau unul din spațiile de cazare aflate chiar în interiorul unei cetăți fortificate. Nu ezita sa ne spui în cazul în care îți dorești ceva personalizat!",
    stepTitle2: "Spune-ne când și cu cine vrei să călătorești",
    stepTitle2Accommodation: "Prețul calculat pentru datele selectate.",
    stepContent2:
      "Completează formularul alături de datele tale de contact. Ne interesează mai ales data la care vrei să călătorești și numărul de persoane. În funcție de asta, vom calcula și prețul total.",
    stepTitle3: "Te sunăm și îți confirmăm datele",
    stepContent3:
      "Vom calcula prețul total și îți vom spune exact disponibilitatea pentru circuitele sau spațiile de cazare alese. Te vom contacta totodată ca să ne confirmi rezervarea.",
    stepTitle3Accommodation:
      "Introdu datele personale. Te asigurăm că vom folosi e-mail-ul și numărul de telefon strict pentru a lua legătura cu tine.",
    homeName: "Acasă",
    need: "Ai nevoie de",
    help: "ajutor?",
    details: "Detalii",
    hours: "ore",
    activities: "activități",
    traditionalLunch: "Prânz tradițional",
    tourGuide: "Ghid turistic",
    wineTasting: "Degustare de vin",
    includedTransportation: "Transport inclus",
    all: "Toate",
    next: "Următorul",
    back: "Înapoi",
    fromPerson: "de la/persoană",
    perNight: "pe noapte",
    from: "de la",
    dryer: "Uscător de păr",
    fridge: "Frigider",
    tv: "TV",
    kitchen: "Bucătărie",
    nonSmoking: "Nefumători",
    parking: "Parcare",
    wifi: "WiFi",
    petFriendly: "Iubim animalele de companie",
    grillPlace: "Spațiu pentru grătar",
    depositBox: "Seif",
    additionalBed: "Pat suplimentar",
    accessability: "Accesabilitate",
    tourAbout: "Despre circuit",
    tourItinerary: "Itinerariu",
    tourGallery: "Galerie foto",
    tourFacilities: "Facilități",
    tourMap: "Hartă",
    summary: "Cuprins",
    tourDurationDescription:
      "Aceasta este durata aproximativă a circuitului. Vom începe dimineață în jurul orei 10 și vom ajunge înapoi în jurul orei 17.",
    tourActivitiesDescription:
      "În funcție de condițiile meteo sau alte situații neprevăzute locațiile pot diferi puțin.",
    tourTraditionalLunchDescription:
      "Acest circuit include și un prânz tradițional care constă din două feluri de mâncare, dar și băuturi alcoolice și non-alcoolice.",
    tourTourGuideDescription:
      "În anumite locații pe care le vom vizita, un ghid turistic îți va explica toate secretele și legendele.",
    tourWineTastingDescription:
      "Degustarea include vinuri specifice zonelor pe care le vom vizita și vei avea posibilitatea chiar să cumperi.",
    tourTransportationDescription:
      "Transportul îl vom face în microbuze dotate cu aer condiționat și încălzire, potrivit indiferent de anotimp.",
    tourLocationsDescription:
      "Locațiile pot să difere în funcție de condițiile meteo sau alte situații neprevăzute, experiențele rămân neschimbate.",
    requestBooking: "Solicită rezervare",
    book: "Rezervă",
    bookNow: 'Rezervă <span class="highlight_red">acum</span>',
    calculatePrice: "Calculează prețul",
    calculatePriceDescription:
      "Folosește acest formular și introdu toate datele solicitate. În funcție de acestea îți vom calcula prețul acestui circuit/spațiu de cazare. Prețul poate să difere în funcție de anotimp, numărul de persoane sau numărul de nopți selectate.",
    date: "Data",
    persons: "Persoane",
    calculate: "Calculează",
    calculatedPrice: "Prețul calculat",
    calculatedPriceDescription:
      "Acest preț a fost calculat în funcție de datele introduse în pasul anterior. Dacă dorești să schimbi datele, mergi înapoi.",
    pricePerPerson: "Preț per persoană",
    pricePerNight: "Preț per noapte",
    nights: "Nopți",
    night: "noapte",
    total: "Total",
    continue: "Continuă",
    personalData: "Introdu datele personale",
    personalDataDescription:
      "Avem nevoie de câteva date personale astfel încât să putem face rezervarea. Te asigurăm că aceste date sunt folosite exclusiv pentru efectuarea rezervării și nu vor fi partajate cu terți parteneri.",
    firstName: "Prenume",
    lastName: "Nume de familie",
    email: "E-Mail",
    phoneInput: "Telefon",
    summaryStep: "Sumar",
    summaryStepDescription:
      "Verifică încă odată datele introduse și prețul calculat. Dacă vrei să modifici ceva poți să te întorci la pașii precendenți. În cazul în care totul este în ordine, poți efectua solicitarea de rezervare.",
    fullName: "Nume",
    bookingThanks: "Iți mulțumim!",
    bookingTourThanksDescription:
      "Solicitarea de rezervare a fost primită! Un email de confirmare alături de sumarul datelor completate a fost trimis pe adresa ta de e-mail. Solicitarea va fi verificată, iar apoi te vom contacta pentru mai multe detalii.",
    bookingError:
      "A apărut o eroare. Poți să încerci din nou sau poți să ne contactezi pe contact@turistintransilvania.com",
    callForDetails: "Sună pentru detalii",
    call: "Sună",
    offerAbout: "Despre ofertă",
    accommodationAbout: "Despre cazare",
    accommodationGallery: "Galerie foto",
    accommodationFacilities: "Facilități",
    accommodationMap: "Hartă",
    accommodationPaxMaxDescription:
      "Acesta este numărul de persoane care pot înopta în acest spațiu de cazare.",
    accommodationDryerDescription:
      "În fiecare cameră vei găsi un uscător de păr pe care îl poți folosi ori de câte ori ai nevoie.",
    accommodationFridgeDescription:
      "Un frigider îți stă la dispoziție în cazul în care vrei să păstrezi la rece diferite alimente.",
    accommodationTvDescription:
      "Camera dispune de un televizor la care vei găsi cele mai importante canale de televiziune.",
    accommodationKitchenDescription:
      "Camera este dotată cu o kitchinetă în care vei găsi strictul necesar pentru a găti o mâncare.",
    accommodationNonSmokingDescription:
      "Camera este exclusiv dedicată persoanelor nefumătoare.",
    accommodationParkingDescription:
      "Vei găsi la această proprietate locuri de parcare ca să îți lași în siguranță automobilul.",
    accommodationWifiDescription:
      "Accessul la Internet Wi-Fi este gratuit și nelimitat.",
    accommodationPetFriendlyDescription:
      "Iubim animalele astfel că acesta poate să te însoțească dacă dorești.",
    accommodationGrillPlaceDescription:
      "Vei avea la dispoziție un loc comun în care să pregătești grătarul.",
    accommodationDepositBoxDescription:
      "Lucrurile de valoare le poți lăsa în seif-ul din cameră sau la recepție.",
    accommodationAdditionalBedDescription:
      "Camera dispune de un pat adițional în cazul în care este nevoie.",
    accommodationAccessabilityDescription:
      "Spațiul de cazare este accesibil persoanelor cu dizabilități.",
    checkIn: "Check-In",
    checkOut: "Check-Out",
    organizationName:
      "Turist in Transilvania | Descoperă, Trăiește, Explorează",
    organizationAddressLocality: "Mediaș, România",
    organizationStreetAddress: "Str. Stadionului Nr. 14A",
    generalOfferName: "Ofertă generală clienți",
    generalOfferDescription:
      "Această ofertă este valabilă pentru toți clienții și include transfer și ghid turistic",
    viewAccommodations: "Vezi spațiile de cazare",
    viewAccommodation: "Vezi spațiul de cazare",
    about: "Despre",
    accommodationsList: "Spații de cazare",
    gallery: "Galerie Foto",
    gettingThere: "Cum ajung?",
    offers: "Oferte",
    home: "Acasă",
  },
  "de-de": {
    path: "de",
    locale: "de-de",
    siteLanguage: "de",
    ogLang: "de_DE",
    hrefLang: "de-DE",
    ogUrl: "https://www.turistintransilvania.com/de",
    title:
      "Tagesausflüge, Unterkünfte und Erlebnisse in Transsilvanien - Tourist in Transsilvanien",
    description:
      "Entdecke Tagesausflüge, eigenartige Unterkünfte und wundervolle Erlebnisse in Transsilvanien",
    headline: "Tourist in Transsilvanien",
    schedule: "Montag - Freitag: 10:00 - 18:00",
    phone: "+40 749 680 770",
    mail: "contact@turistintransilvania.com",
    langName: "Deutsch",
    german: "Deutsch",
    germanShort: "DE",
    romanian: "Română",
    romanianShort: "RO",
    english: "English",
    englishShort: "EN",
    fbUrl: "https://facebook.com/turistintransilvania",
    instaUrl: "https://www.instagram.com/touristintransylvania",
    helpNeeded: "Benötigst Du Hilfe?",
    aboutUs: "Über Uns",
    menu: "Menü",
    discover: "Entdecken",
    language: "Sprache",
    tours: "Rundreisen",
    toursSlogan: "Tagesausflüge in das Herz vom Transsilvanien",
    inTransylvania: "in Transsilvanien",
    viewAllTours: "Alle Tagesausflüge anzeigen",
    seeMore: "Zeige mir mehr",
    accommodations: "Übernachtung",
    accommodationsSlogan:
      "Einzigartige Übernachtungsplätze im Kirchenburgen und historische Gebäuden",
    priceFrom: "Preise ab",
    viewAllAccommodations: "Alle Übernachtungen anzeigen",
    why: "Warum",
    turistInTransilvania: "Tourist in Transsilvanien",
    reasonsSlogan:
      "Drei Gründe warum du unsere Tagesausflüge und Übernachtungsplätze auswählen solltest",
    stepTitle1: "Wählen Sie Ihre bevorzugtes Tagesausflug oder Unterkunft",
    stepTitle1Accommodation:
      "Wählen Sie das Datum des Ein- und Auscheckens sowie die Anzahl der Personen.",
    stepContent1:
      "Wählen Sie einen von den historischen, kulinarischen, Wander- oder Wellnesstagesausflüge oder eine  Unterkunft in der Wehrkirche Frauwndorf. Zögern Sie nicht, uns mitzuteilen, ob Sie etwas Personalisiertes wünschen!",
    stepTitle2: "Sagen Sie uns, wann und mit wem Sie reisen möchten",
    stepTitle2Accommodation: "Preis berechnet für die ausgewählten Daten.",
    stepContent2:
      "Füllen Sie das Formular zusammen mit Ihren Kontaktdaten aus. Wir sind besonders an dem Datum interessiert, an dem Sie reisen möchten, und an der Anzahl der Personen. Abhängig davon berechnen wir auch den Gesamtpreis.",
    stepTitle3: "Wir rufen Sie an und bestätigen Ihre Daten",
    stepTitle3Accommodation:
      "Geben Sie Ihre persönlichen Daten ein. Wir versichern Ihnen, dass wir Ihre E-Mail-Adresse und Telefonnummer ausschließlich zur Kontaktaufnahme mit Ihnen verwenden.",
    stepContent3:
      "Wir berechnen den Gesamtpreis und teilen Ihnen genau die Verfügbarkeit für die ausgewählten Tagesausflüge oder Unterkünfte mit. Wir werden uns auch mit Ihnen in Verbindung setzen, um unsere Reservierung zu bestätigen.",
    homeName: "Hauptseite",
    need: "Benötigst du",
    help: "Hilfe?",
    details: "Mehr",
    hours: "Stunden",
    activities: "Aktivitäten",
    traditionalLunch: "Traditionelles Mittagessen",
    tourGuide: "Reiseführer",
    wineTasting: "Weinprobe",
    includedTransportation: "Transport inklusive",
    all: "Alle",
    next: "Nächstes",
    back: "Zurück",
    fromPerson: "ab/Person",
    perNight: "pro Nacht",
    from: "ab",
    dryer: "Haartrockner",
    fridge: "Kühlschrank",
    tv: "TV",
    kitchen: "Kochnische",
    nonSmoking: "Nichtraucher",
    parking: "Parkplatz",
    wifi: "WiFi",
    petFriendly: "Wir lieben Haustiere",
    grillPlace: "Grillplatz",
    depositBox: "Safe",
    additionalBed: "Extra Bett",
    accessability: "Barrierefreiheit",
    tourAbout: "Tagesausflug-Übersicht",
    tourItinerary: "Route",
    tourGallery: "Fotogallerie",
    tourFacilities: "Info",
    tourMap: "Karte",
    summary: "Inhalt",
    tourDurationDescription:
      "Dies ist die ungefähre Dauer des Tagesausflugs. Wir fangen morgens gegen 10 Uhr an und kommen gegen 5 Uhr zurück.",
    tourActivitiesDescription:
      "Abhängig von den Wetterbedingungen oder anderen unvorhergesehenen Situationen können sich die Standorte geringfügig unterscheiden.",
    tourTraditionalLunchDescription:
      "Acest circuit include și un prânz tradițional care constă din două feluri de mâncare, dar și băuturi alcoolice și non-alcoolice.",
    tourTourGuideDescription:
      "Dieses Tagesausflug beinhaltet auch ein traditionelles Mittagessen, das aus zwei Gerichten sowie alkoholischen und alkoholfreien Getränken besteht.",
    tourWineTastingDescription:
      "Die Verkostung umfasst Weine, die für die von uns besuchten Gebiete spezifisch sind. Sie haben sogar die Möglichkeit, diese zu kaufen.",
    tourTransportationDescription:
      "Wir transportieren Sie in Kleinbussen mit Klimaanlage und Heizung, die unabhängig von der Jahreszeit geeignet sind.",
    tourLocationsDescription:
      "Die Standorte können je nach Wetterbedingungen oder anderen unvorhergesehenen Situationen unterschiedlich sein. Die Erfahrungen bleiben  jedoch unverändert.",
    requestBooking: "Reservierungsanfrage",
    book: "Buchen",
    bookNow: 'Jetzt <span class="highlight_red">buchen</span>',
    calculatePrice: "Preis berechnen",
    calculatePriceDescription:
      "Verwenden Sie dieses Formular und geben Sie alle erforderlichen Daten ein. Basierend auf diesen berechnen wir den Preis dieser Tagesausflug/Unterkunft. Der Preis kann je nach Jahreszeit, Anzahl der Personen oder Anzahl de Nächte unterschiedlich sein.",
    date: "Datum",
    persons: "Personen",
    calculate: "Berechnen",
    calculatedPrice: "Berechnete Preis",
    calculatedPriceDescription:
      "Dieser Preis wurde anhand der im vorherigen Schritt eingegebenen Daten berechnet. Wenn Sie die Daten ändern möchten, gehen Sie zurück.",
    pricePerPerson: "Preis pro Person",
    pricePerNight: "Preis pro Nacht",
    nights: "Nächte",
    night: "Nacht",
    total: "Gesamtsumme",
    continue: "Weiter",
    personalData: "Geben Sie Ihre persönlichen Daten ein",
    personalDataDescription:
      "Wir benötigen einige personenbezogene Daten, damit wir die Reservierung vornehmen können. Wir versichern Ihnen, dass diese Daten ausschließlich für die Reservierung verwendet und nicht an Dritte weitergegeben werden.",
    firstName: "Vorname",
    lastName: "Nachname",
    email: "E-Mail",
    phoneInput: "Telefon",
    summaryStep: "Zusammenfassung",
    summaryStepDescription:
      "Überprüfen Sie erneut die eingegebenen Daten und den berechneten Preis. Wenn Sie etwas ändern möchten, können Sie zu den vorherigen Schritten zurückkehren. Wenn alles in Ordnung ist, können Sie eine Reservierungsanfrage stellen.",
    fullName: "Name",
    bookingThanks: "Vielen Dank!",
    bookingTourThanksDescription:
      "Reservierungsanfrage erhalten! Eine Bestätigungs-E-Mail mit einer Zusammenfassung der vollständigen Daten wurde an Ihre E-Mail-Adresse gesendet. Die Anfrage wird überprüft und wir werden uns mit Ihnen in Verbindung setzen, um weitere Informationen zu erhalten.",
    bookingError:
      "Ein Fehler ist aufgetreten. Sie können es erneut versuchen oder uns unter contact@turistintransilvania.com kontaktieren",
    callForDetails: "Rufen Sie uns an",
    call: "Anrufen",
    offerAbout: "Angebot-Übersicht",
    accommodationAbout: "Unterkunft-Übersicht",
    accommodationGallery: "Fotogallerie",
    accommodationFacilities: "Info",
    accommodationMap: "Karte",
    accommodationPaxMaxDescription:
      "Dies ist die Anzahl der Personen, die in dieser Unterkunft übernachten können.",
    accommodationDryerDescription:
      "In jedem Zimmer finden Sie einen Haartrockner, den Sie jederzeit nutzen können.",
    accommodationFridgeDescription:
      "Für den Fall, dass Sie verschiedene Lebensmittel kalt halten möchten, steht Ihnen ein Kühlschrank zur Verfügung.",
    accommodationTvDescription:
      "Das Zimmer verfügt über einen Fernseher, auf dem Sie die wichtigsten Fernsehkanäle finden.",
    accommodationKitchenDescription:
      "Das Zimmer ist mit einer Küchenzeile ausgestattet, in der Sie alles finden, was Sie zum Kochen benötigen.",
    accommodationNonSmokingDescription:
      "Das Zimmer ist ausschließlich für Nichtraucher.",
    accommodationParkingDescription:
      "In dieser Unterkunft finden Sie Parkplätze, auf denen Sie Ihr Auto sicher abstellen können.",
    accommodationWifiDescription:
      "Der WLAN-Internetzugang ist kostenlos und unbegrenzt.",
    accommodationPetFriendlyDescription:
      "Wir lieben Tiere, damit er Sie begleiten kann, wenn Sie wollen.",
    accommodationGrillPlaceDescription:
      "Sie haben einen gemeinsamen Ort, um den Grill vorzubereiten.",
    accommodationDepositBoxDescription:
      "Sie können Ihre Wertsachen im Zimmersafe oder an der Rezeption aufbewahren.",
    accommodationAdditionalBedDescription:
      "Das Zimmer verfügt bei Bedarf über ein Zustellbett.",
    accommodationAccessabilityDescription:
      "Die Unterkunft ist für Behinderte zugänglich.",
    checkIn: "Check-In",
    checkOut: "Check-Out",
    organizationName: "Turist in Transilvania | Entdecken, Erleben, Reisen",
    organizationAddressLocality: "Mediasch, Rumänien",
    organizationStreetAddress: "Stadionului 14A Straße",
    generalOfferName: "Allgemeines Kundenangebot",
    generalOfferDescription:
      "Dieses Angebot gilt für alle Kunden und beinhaltet Transfer und Reiseleiter",
    viewAccommodations: "Übernachtungsplätze anzeigen",
    viewAccommodation: "Übernachtung anzeigen",
    about: "Über",
    accommodationsList: "Verfügbare Zimmer",
    gallery: "Fotos",
    gettingThere: "Anfahrt",
    offers: "Angebote",
    home: "Hauptseite",
  },
  "en-us": {
    path: "en",
    locale: "en-us",
    siteLanguage: "en",
    ogLang: "en_US",
    hrefLang: "en-US",
    ogUrl: "https://www.turistintransilvania.com/en",
    title:
      "Tours, accommodations and experiences in Transylvania - Tourist in Transylvania",
    description:
      "Discover daily tours, unique accommodations and real experiences in Transylvania",
    headline: "Tourist in Transylvania",
    schedule: "Monday - Sunday: 10:00 - 18:00",
    phone: "+40 749 680 770",
    mail: "contact@turistintransilvania.com",
    langName: "English",
    german: "Deutsch",
    germanShort: "DE",
    romanian: "Română",
    romanianShort: "RO",
    english: "English",
    englishShort: "EN",
    fbUrl: "https://facebook.com/turistintransilvania",
    instaUrl: "https://www.instagram.com/touristintransylvania",
    helpNeeded: "Do you need help?",
    aboutUs: "About Us",
    menu: "Menu",
    discover: "Discover",
    language: "Language",
    tours: "Tours",
    toursSlogan: "Daily tours in the hearth of Transylvania",
    inTransylvania: "in Transylvania",
    viewAllTours: "View all tours",
    seeMore: "Show me more",
    accommodations: "Accommodations",
    accommodationsSlogan:
      "Unique accommodations in medieval citadels and buildings.",
    priceFrom: "Price from",
    viewAllAccommodations: "View all accommodations",
    why: "Why",
    turistInTransilvania: "Tourist in Transylvania",
    reasonsSlogan:
      "Three reasons why you should choose our tours and accommodations",
    stepTitle1: "Choose your preferred tour or accommodation",
    stepTitle1Accommodation:
      "Select check-in and check-out date and number of people.",
    stepContent1:
      "Choose one of the historical, culinary, hiking or wellness tours or one of the accommodation spaces right inside a fortified fortress. Do not hesitate to tell us if you want something personalized!",
    stepTitle2: "Tell us when and with whom you want to travel",
    stepTitle2Accommodation: "The price calculated for the selected dates.",
    stepContent2:
      "Fill in the form together with your contact details. We are especially interested in the date you want to travel and the number of people. Based on this, we will also calculate the total price.",
    stepTitle3: "We call you and confirm your data",
    stepTitle3Accommodation:
      "Enter your personal data. We assure you that we will use your email and telephone number strictly to contact you.",
    stepContent3:
      "We will calculate the total price and tell you exactly the availability for the chosen circuits or accommodation spaces. We will also contact you to confirm our reservation.",
    homeName: "Home",
    need: "Do you need",
    help: "help?",
    details: "Details",
    hours: "hours",
    activities: "activities",
    traditionalLunch: "Traditional Lunch",
    tourGuide: "Tour Guide",
    wineTasting: "Wine Tasting",
    includedTransportation: "Transportation Included",
    all: "All",
    next: "Next",
    back: "Back",
    fromPerson: "from/person",
    perNight: "per night",
    from: "from",
    dryer: "Dryer",
    fridge: "Fridge",
    tv: "TV",
    kitchen: "Kitchen",
    nonSmoking: "Non-smoking",
    parking: "Parking",
    wifi: "WiFi",
    petFriendly: "We love pets",
    grillPlace: "BBQ area",
    depositBox: "Safe",
    additionalBed: "Additional bed",
    accessability: "Accessability",
    tourAbout: "About the tour",
    tourItinerary: "Itinerary",
    tourGallery: "Photo Gallery",
    tourFacilities: "Facilities",
    tourMap: "Map",
    summary: "Summary",
    tourDurationDescription:
      "This is the approximate duration of the circuit. We'll start in the morning around 10 o'clock and get back around 5 o'clock.",
    tourActivitiesDescription:
      "Depending on weather conditions or other unforeseen situations, locations may differ slightly.",
    tourTraditionalLunchDescription:
      "Acest circuit include și un prânz tradițional care constă din două feluri de mâncare, dar și băuturi alcoolice și non-alcoolice.",
    tourTourGuideDescription:
      "This circuit also includes a traditional lunch consisting of two dishes, as well as alcoholic and non-alcoholic beverages.",
    tourWineTastingDescription:
      "The tasting includes wines specific to the areas we will visit and you will even have the opportunity to buy.",
    tourTransportationDescription:
      "We will transport you in minibuses equipped with air conditioning and heating, suitable regardless of the season.",
    tourLocationsDescription:
      "Locations may differ depending on weather conditions or other unforeseen situations, experiences remain unchanged.",
    requestBooking: "Request Booking",
    book: "Book",
    bookNow: 'Book <span class="highlight_red">now</span>',
    calculatePrice: "Calculate price",
    calculatePriceDescription:
      "Use this form and enter all the required data. Based on these we will calculate the price of this circuit/accommodation. The price may differ depending on the season, the number of people or selected nights.",
    date: "Date",
    persons: "Persons",
    calculate: "Calculate",
    calculatedPrice: "Calculated price",
    calculatedPriceDescription:
      "This price was calculated based on the data entered in the previous step. If you want to change the data, go back.",
    pricePerPerson: "Price per person",
    pricePerNight: "Price per night",
    nights: "Nights",
    night: "night",
    total: "Total",
    continue: "Continue",
    personalData: "Enter your personal data",
    personalDataDescription:
      "We need some personal data so that we can make the reservation. We assure you that this data is used exclusively for making the reservation and will not be shared with third parties.",
    firstName: "First Name",
    lastName: "Last Name",
    email: "E-Mail",
    phoneInput: "Phone",
    summaryStep: "Summary",
    summaryStepDescription:
      "Check again the data entered and the calculated price. If you want to change something you can go back to the previous steps. If everything is in order, you can make a reservation request.",
    fullName: "Name",
    bookingThanks: "Thank you!",
    bookingTourThanksDescription:
      "Reservation request received! A confirmation email along with a summary of the completed data has been sent to your email address. The request will be verified, and then we will contact you for more details.",
    bookingError:
      "An error occurred. You can try again or you can contact us at contact@turistintransilvania.com",
    callForDetails: "Call for details",
    call: "Call",
    offerAbout: "About the offer",
    accommodationAbout: "About the accommodation",
    accommodationGallery: "Photo Gallery",
    accommodationFacilities: "Facilities",
    accommodationMap: "Map",
    accommodationPaxMaxDescription:
      "This is the number of people who can spend the night in this accommodation.",
    accommodationDryerDescription:
      "In each room you will find a hair dryer that you can use whenever you need.",
    accommodationFridgeDescription:
      "A refrigerator is at your disposal in case you want to keep different foods cold.",
    accommodationTvDescription:
      "The room has a TV on which you will find the most important television channels.",
    accommodationKitchenDescription:
      "The room is equipped with a kitchenette where you will find everything you need to cook.",
    accommodationNonSmokingDescription:
      "The room is exclusively for non-smokers.",
    accommodationParkingDescription:
      "You will find parking spaces at this property to leave your car safe.",
    accommodationWifiDescription:
      "Wi-Fi internet access is free and unlimited.",
    accommodationPetFriendlyDescription:
      "We love animals so he can accompany you if you want.",
    accommodationGrillPlaceDescription:
      "You will have a common place to prepare the grill.",
    accommodationDepositBoxDescription:
      "You can leave your valuables in the in-room safe or at the reception.",
    accommodationAdditionalBedDescription:
      "The room has an extra bed if needed.",
    accommodationAccessabilityDescription:
      "The accommodation is accessible to the disabled.",
    checkIn: "Check-In",
    checkOut: "Check-Out",
    organizationName: "Turist in Transilvania | Discover, Live, Travel",
    organizationAddressLocality: "Medias, Romania",
    organizationStreetAddress: "Stadionului 14A",
    generalOfferName: "General customer offer",
    generalOfferDescription:
      "This offer is valid for all customers and includes transfer and tour guide",
    viewAccommodations: "View accommodations",
    viewAccommodation: "View accommodation",
    about: "About",
    accommodationsList: "Available accommodations",
    gallery: "Photo Gallery",
    gettingThere: "Getting There",
    offers: "Offers",
    home: "Home",
  },
}
