// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("./../../../node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-templates-about-us-page-js": () => import("./../../../src/templates/aboutUsPage.js" /* webpackChunkName: "component---src-templates-about-us-page-js" */),
  "component---src-templates-accommodation-js": () => import("./../../../src/templates/accommodation.js" /* webpackChunkName: "component---src-templates-accommodation-js" */),
  "component---src-templates-accommodations-category-js": () => import("./../../../src/templates/accommodationsCategory.js" /* webpackChunkName: "component---src-templates-accommodations-category-js" */),
  "component---src-templates-accommodations-landing-js": () => import("./../../../src/templates/accommodationsLanding.js" /* webpackChunkName: "component---src-templates-accommodations-landing-js" */),
  "component---src-templates-faq-page-js": () => import("./../../../src/templates/faqPage.js" /* webpackChunkName: "component---src-templates-faq-page-js" */),
  "component---src-templates-static-page-js": () => import("./../../../src/templates/staticPage.js" /* webpackChunkName: "component---src-templates-static-page-js" */),
  "component---src-templates-tour-js": () => import("./../../../src/templates/tour.js" /* webpackChunkName: "component---src-templates-tour-js" */),
  "component---src-templates-tours-category-js": () => import("./../../../src/templates/toursCategory.js" /* webpackChunkName: "component---src-templates-tours-category-js" */),
  "component---src-templates-tours-page-js": () => import("./../../../src/templates/toursPage.js" /* webpackChunkName: "component---src-templates-tours-page-js" */)
}

