import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import CssBaseline from "@material-ui/core/CssBaseline"
import {
  ThemeProvider as MuiThemeProvider,
  makeStyles,
} from "@material-ui/core/styles"

import theme from "../styles/theme"
import Footer from "./Footer"
import Header from "./Header"

import "../styles/base.scss"

const useStyles = makeStyles(theme => ({
  mainContent: {
    marginBottom: 50,

    [theme.breakpoints.down("sm")]: {
      paddingTop: 60,
    },
  },
}))

const Layout = ({ children, pageContext: { locale, alternateLanguages } }) => {
  const classes = useStyles()
  const data = useStaticQuery(query)

  const headerMenu = data.allPrismicHeaderMenu.edges
    .filter(edge => edge.node.lang === locale)
    .map(r => r.node.data.links)

  const footerMenu = data.allPrismicFooterMenu.edges
    .filter(edge => edge.node.lang === locale)
    .map(r => r.node.data.links)

  const footerMenu2 = data.allPrismicFooterMenu.edges
    .filter(edge => edge.node.lang === locale)
    .map(r => r.node.data.links_2)

  return (
    <MuiThemeProvider theme={theme}>
      <>
        <CssBaseline />

        <Header menu={headerMenu[0]} alternateLanguages={alternateLanguages} />

        <div className={classes.mainContent}>{children}</div>

        <Footer
          menu={footerMenu[0]}
          menu2={footerMenu2[0]}
          alternateLanguages={alternateLanguages}
        />
      </>
    </MuiThemeProvider>
  )
}

export { Layout }

const query = graphql`
  query LayoutQuery {
    allPrismicHeaderMenu {
      edges {
        node {
          lang
          data {
            title
            links {
              label
              url {
                link_type
                url
                target
              }
              internal_link
            }
          }
        }
      }
    }

    allPrismicFooterMenu {
      edges {
        node {
          lang
          data {
            title
            links {
              label
              internal_link
            }
            links_2 {
              label
              url {
                link_type
                url
                target
              }
              internal_link
            }
          }
        }
      }
    }
  }
`

Layout.propTypes = {
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.node]).isRequired,
  pageContext: PropTypes.shape({
    locale: PropTypes.string.isRequired,
    alternateLanguages: PropTypes.array.isRequired,
    breadcrumbs: PropTypes.array.isRequired.isRequired,
  }).isRequired,
}
