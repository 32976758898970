import { red } from "@material-ui/core/colors"
import { createMuiTheme } from "@material-ui/core/styles"

// A custom theme for this app
const theme = createMuiTheme({
  typography: {
    fontFamily: [
      "Montserrat",
      "Roboto Condensed",
      "Oxygen",
      '"Helvetica Neue"',
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Oxygen",
      "Arial",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    fontWeightRegular: 300,
    fontWeightBold: 700,
    letterSpacing: 1.1,
    fontSize: 14,
    h1: {
      fontSize: "1.8rem",
    },
    h2: {
      fontSize: "1.5rem",
    },
    h3: {
      fontSize: "1.45rem",
    },
    h4: {
      fontSize: "1.5rem",
      fontWeight: 700,
    },
    h5: {
      fontSize: "1.5rem",
    },
    h6: {
      fontSize: "1.25rem",
    },
  },
  palette: {
    text: {
      primary: "#333",
      secondary: "#666",
    },
    primary: {
      light: "#a64048",
      main: "#90111b",
      dark: "#640b12",
    },
    secondary: {
      light: "#68c0e3",
      main: "#51bce6",
      dark: "#4f9ab8",
    },
    common: {
      black: "#333333",
    },
    error: {
      main: red.A400,
    },
    background: {
      default: "#f9f9f9",
    },
    turistBlue: {
      main: "#51bce6",
    },
  },
})

export default theme
