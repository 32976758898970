/* eslint-disable import/no-cycle */
import React from "react"
import shortid from "shortid"
import PropTypes from "prop-types"
import { navigate, Link } from "gatsby"
import { makeStyles, withStyles } from "@material-ui/core/styles"
import {
  Container,
  FormControl,
  Grid,
  IconButton,
  InputBase,
  Link as MaterialLink,
  MenuItem,
  Select,
  Typography,
} from "@material-ui/core"

import PhoneIcon from "@material-ui/icons/Phone"
import MailOutlineIcon from "@material-ui/icons/MailOutline"
import FacebookIcon from "@material-ui/icons/Facebook"
import InstagramIcon from "@material-ui/icons/Instagram"

import { LocaleContext } from "../contexts/LocaleContext"

import BackgroundPattern from "../img/pattern.png"

const useStyles = makeStyles(theme => ({
  footer: {
    backgroundImage: `url(${BackgroundPattern})`,
    backgroundColor: "#333",
    backgroundRepeat: "repeat",
  },

  footerTop: {
    padding: "20px 0",
  },

  title: {
    color: theme.palette.common.white,
    fontWeight: 700,
    fontSize: 16,
    display: "block",
    marginBottom: 5,
  },

  contactLink: {
    color: "#fdf7ac",
    display: "flex",
    fontSize: 16,
    padding: "5px 0",

    "&:hover": {
      textDecoration: "none",
      color: theme.palette.common.white,
    },
  },

  menu: {
    padding: 0,

    "& li": {
      listStyleType: "none",
    },
  },
  menuLink: {
    color: theme.palette.common.white,
    textDecoration: "none",

    "&:hover": {
      textDecoration: "underline",
    },
  },

  languageForm: {
    maxWidth: 200,
    width: "100%",
  },

  socialButton: {
    color: theme.palette.common.white,
  },
  socialLinks: {
    borderTop: "1px solid rgba(255, 255, 255, 0.2)",
    padding: "20px 0",
  },

  copyright: {
    color: "#8c8c8c",
  },
}))

const BootstrapInput = withStyles(theme => ({
  root: {
    "label + &": {
      marginTop: theme.spacing(3),
    },
  },
  input: {
    borderRadius: 4,
    color: "#999",
    position: "relative",
    textTransform: "lowercase",
    backgroundColor: "#444",
    fontSize: 12,
    padding: "10px 26px 10px 12px",
    // Use the system font instead of the default Roboto font.
    "&:focus": {
      borderRadius: 4,
      backgroundColor: "#444",
    },
  },
}))(InputBase)

const Footer = ({ menu, menu2, alternateLanguages }) => {
  const lang = React.useContext(LocaleContext)
  const i18n = lang.i18n[lang.locale]

  const classes = useStyles()

  const handleChangeLanguage = event => {
    navigate(`${event.target.value}`)
  }

  const getAlternateLanguageLink = key => {
    if (alternateLanguages) {
      const alternateLanguage = alternateLanguages.find(al => al.lang === key)

      if (alternateLanguage) {
        return alternateLanguage.path
      }
    }

    return `/${lang.i18n[key].path}/`
  }

  return (
    <footer className={classes.footer}>
      <Container>
        <Grid
          container
          direction="row"
          justify="space-between"
          alignItems="flex-start"
          spacing={4}
          className={classes.footerTop}
        >
          <Grid item xs={12} md={4}>
            <Typography component="span" className={classes.title}>
              {i18n.helpNeeded}
            </Typography>
            <MaterialLink
              href={`tel://${i18n.phone}`}
              className={classes.contactLink}
            >
              <PhoneIcon style={{ fontSize: 24 }} />
              &nbsp;&nbsp;{i18n.phone}
            </MaterialLink>
            <MaterialLink
              href={`mailto:${i18n.mail}`}
              className={classes.contactLink}
            >
              <MailOutlineIcon style={{ fontSize: 24 }} />
              &nbsp;&nbsp;{i18n.mail}
            </MaterialLink>
          </Grid>
          <Grid item xs={12} md={3}>
            <Typography component="span" className={classes.title}>
              {i18n.aboutUs}
            </Typography>
            <ul className={classes.menu}>
              {menu.map(item => (
                <li key={shortid.generate()}>
                  <Link to={item.internal_link} className={classes.menuLink}>
                    {item.label}
                  </Link>
                </li>
              ))}
            </ul>
          </Grid>

          <Grid item xs={12} md={3}>
            <Typography component="span" className={classes.title}>
              {i18n.menu}
            </Typography>
            <ul className={classes.menu}>
              {menu2.map(item => (
                <li key={shortid.generate()}>
                  {item.internal_link && (
                    <Link to={item.internal_link} className={classes.menuLink}>
                      {item.label}
                    </Link>
                  )}

                  {item.url && item.url.target && (
                    <MaterialLink
                      href={item.url.url}
                      className={classes.menuLink}
                      target={item.url.target}
                    >
                      {item.label}
                    </MaterialLink>
                  )}
                </li>
              ))}
            </ul>
          </Grid>

          <Grid item xs={12} md={2}>
            <Typography component="span" className={classes.title}>
              {i18n.language}
            </Typography>
            <FormControl variant="outlined" className={classes.languageForm}>
              <Select
                id="language-select"
                value={getAlternateLanguageLink(lang.locale)}
                onChange={handleChangeLanguage}
                input={<BootstrapInput />}
              >
                {Object.keys(lang.i18n).map(key => (
                  <MenuItem
                    key={shortid.generate()}
                    value={getAlternateLanguageLink(key)}
                  >
                    {lang.i18n[key].langName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>

        <Grid
          container
          direction="column"
          justify="center"
          alignItems="center"
          className={classes.socialLinks}
          spacing={2}
        >
          <Grid item>
            <IconButton
              target="_blank"
              component={MaterialLink}
              href="https://www.facebook.com/turistintransilvania"
              aria-label="facebook"
              className={classes.socialButton}
            >
              <FacebookIcon style={{ fontSize: 30 }} />
            </IconButton>

            <IconButton
              component={MaterialLink}
              target="_blank"
              href="https://www.instagram.com/touristintransylvania/"
              aria-label="instagram"
              className={classes.socialButton}
            >
              <InstagramIcon style={{ fontSize: 30 }} />
            </IconButton>
          </Grid>
          <Grid item>
            <Typography component="span" className={classes.copyright}>
              © Turist in Transilvania {new Date().getFullYear()}
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </footer>
  )
}

export default Footer

Footer.propTypes = {
  menu: PropTypes.oneOfType([PropTypes.array]).isRequired,
  menu2: PropTypes.oneOfType([PropTypes.array]).isRequired,
  alternateLanguages: PropTypes.oneOfType([PropTypes.array, PropTypes.node])
    .isRequired,
}
